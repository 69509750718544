import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UrlFactory } from '../utils/url-factory';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  uuid$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private _http: HttpClient) {}

  validateUrlId(urlId: string): Observable<boolean> {
    const url = UrlFactory.searchTransaction();
    const body: { uuid: string } = {
      uuid: urlId,
    };
    return this._http.post<boolean>(url, body);
  }
}
