<ion-content class="ion-padding ion-text-center h-full ion-no-padding">
  <ion-grid class="h-full">
    <div class="h-full flex flex-col justify-around items-center">
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <section class="home-header flex flex-col items-center">
          <ion-img src="../../../assets/obd-logo.svg"> </ion-img>
          <ion-text>
            <span class="home-header__span text-base font-bold leading-5"
              >Bienvenido</span
            >
          </ion-text>
          <p class="text-2xl font-semibold leading-7">
            <ion-text> Onboarding Digital </ion-text>
          </p>
          <div class="w-80 ion-padding color-danger" *ngIf="showUrlError">
            <p class="text-2xl font-bold">
              <ion-text color="danger"> Link no válido </ion-text>
            </p>
            <p class="text-xl font-semibold">
              <ion-text color="danger">
                Solicite un nuevo link para continuar
              </ion-text>
            </p>
          </div>
        </section>
      </ion-row>
      <ion-row
        class="ion-justify-content-center ion-align-items-center ion-padding"
      >
        <section class="flex flex-col items-center">
          <ion-button
            (click)="navigateToTutorial()"
            expand="block"
            class="w-80 h-10"
            shape="round"
            [disabled]="isButtonBlocked"
          >
            <span class="button-text">Comenzar</span>
          </ion-button>
        </section>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <section class="flex flex-row justify-between items-center gap-1">
          <ion-text>
            <span>Powered by</span>
          </ion-text>
          <ion-img
            class="footer-logo w-9 h-9"
            src="../../../assets/obd-logo.svg"
          >
          </ion-img>
          <ion-text class="font-bold leading-5 text-base">
            <span>Onboarding Digital</span>
          </ion-text>
        </section>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
