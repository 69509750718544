<div class="container-404">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    class="whistle"
    viewBox="0 0 1000 1000"
  >
    <path
      d="M4295.8 3963.2c-113-57.4-122.5-107.2-116.8-622.3l5.7-461.4 63.2-55.5c72.8-65.1 178.1-74.7 250.8-24.9 86.2 61.3 97.6 128.3 97.6 584 0 474.8-11.5 526.5-124.5 580.1-78.4 38.3-99.4 38.3-176 0zM3053.1 3134.2c-68.9-42.1-111-143.6-93.8-216.4 7.7-26.8 216.4-250.8 476.8-509.3 417.4-417.4 469.1-463.4 526.5-463.4 128.3 0 212.5 88.1 212.5 224 0 67-26.8 97.6-434.6 509.3-241.2 241.2-459.5 449.9-488.2 465.3-70.9 36.4-128.3 34.5-199.2-9.5zM2653 1529.7C1644 1445.4 765.1 850 345.8-32.7 62.4-628.2 22.2-1317.4 234.8-1960.8 451.1-2621.3 947-3186.2 1584.6-3500.2c1018.6-501.6 2228.7-296.8 3040.5 515.1 317.8 317.8 561 723.7 670.1 1120.1 101.5 369.5 158.9 455.7 360 553.3 114.9 57.4 170.4 65.1 1487.7 229.8 752.5 93.8 1392 181.9 1420.7 193.4C8628.7-857.9 9900 1250.1 9900 1328.6c0 84.3-67 172.3-147.4 195.3-51.7 15.3-790.8 19.1-2558 15.3l-2487.2-5.7-55.5-63.2-55.5-61.3V719.8H3773v325.5c0 509.3 11.5 499.7-616.5 494-235.5-2-461.4-5.8-503.5-9.6z"
      transform="matrix(.1 0 0 -.1 0 511)"
    ></path>
  </svg>
  <h2 class="h2-404">¡Ups! No se encontro la vista solicitada</h2>
</div>
