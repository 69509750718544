import { Component } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { FaceApiService } from './services/face-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'onboarding-digital';
  constructor(private _faceApiService: FaceApiService) {
    defineCustomElements(window);
  }
}
