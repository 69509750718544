class ApiUrl {
  static baseUrl = 'https://obd.entreconsultas.com/api/';
  static path = {
    validate: 'validar/',
    searchTransaction: 'SearchTransaction/',
  };
}

export class UrlFactory {
  static getValidateUrl(): string {
    return `${ApiUrl.baseUrl}${ApiUrl.path.validate}`;
  }

  static searchTransaction(): string {
    return `${ApiUrl.baseUrl}${ApiUrl.path.searchTransaction}`;
  }
}
