import { Injectable } from '@angular/core';
import { ScanningGuide } from '../interfaces/scanningGuide';

@Injectable()
export class GlobalConstants {
  gestures: ScanningGuide[] = [
    {
      gestureCode: 103,
      title: 'Foto Rostro - Abriendo la boca',
      description:
        'Asegurate de que haya buena iluminación y tomate una foto de tu rostro mientras abres la boca',
      urlImage: '../../../../assets/images/scanning/gestures/open-mouth.png',
    },
    {
      gestureCode: 106,
      title: 'Foto Rostro - Guiñando el ojo',
      description:
        'Asegurate de que haya buena iluminación y tomate una foto de tu rostro mientras guiñas un ojo',
      urlImage: '../../../../assets/images/scanning/gestures/wink.png',
    },
    {
      gestureCode: 108,
      title: 'Foto Rostro - Sonriendo',
      description:
        'Asegurate de que haya buena iluminación y tomate una foto de tu rostro mientras sonries',
      urlImage: '../../../../assets/images/scanning/gestures/smile.png',
    },
  ];
}
