import { inject, Injectable } from '@angular/core';
import type { CanActivateChildFn, Router } from '@angular/router';
import { ScanningService } from '../services/scanning.service';

@Injectable({
  providedIn: 'root',
})
class cannotSkipTutorialService {
  constructor(private _scanningService: ScanningService) {}

  canActivateChild(childRoute: any, state: any): boolean {
    return this._scanningService.tutorialPassed;
  }
}

export const cannotSkipTutorialGuard: CanActivateChildFn = (
  childRoute,
  state
) => {
  return inject(cannotSkipTutorialService).canActivateChild(childRoute, state);
};
