import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
class PlatformService {
  private URL: string = '/platformError';
  constructor(private _router: Router, private _platform: Platform) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const platform = this._platform.platforms();
    if (platform.includes('desktop')) {
      this._router.navigate([this.URL]);
    }
    return true;
  }
}

export const PlatformGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PlatformService).canActivate(next, state);
};
