import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { PlatformGuard } from './guards/platform.guard';
import { ErrorPlatformComponent } from './errors/error-platform/error-platform.component';
import { Error403Component } from './errors/error403/error403.component';
import { Error404Component } from './errors/error404/error404.component';
import { ScanningGuideComponent } from './views/scanning/scanning-guide/scanning-guide.component';
import { ThanksComponent } from './views/thanks/thanks.component';
import { cannotSkipTutorialGuard } from './guards/cannotSkipTutorial.guard';

//TODO: Separate routes into their own modules for Lazy-Loading

const routes: Routes = [
  {
    path: 'start',
    children: [
      { path: '', component: HomeComponent },
      { path: ':id', component: HomeComponent },
    ],
    canActivate: [PlatformGuard],
  },
  {
    path: 'tutorial',
    loadChildren: () =>
      import('./views/tutorial/tutorial.module').then((m) => m.TutorialModule),
    canActivate: [PlatformGuard],
  },
  {
    path: 'scanning',
    loadChildren: () =>
      import('./views/scanning/scanning.module').then((m) => m.ScanningModule),
    canActivateChild: [cannotSkipTutorialGuard],
  },
  {
    path: 'thanks',
    component: ThanksComponent,
  },
  {
    path: 'platformError',
    component: ErrorPlatformComponent,
  },
  {
    path: 'error403',
    component: Error403Component,
  },
  {
    path: 'error404',
    component: Error404Component,
  },
  {
    path: '**',
    redirectTo: 'start',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
