import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HomeComponent } from './views/home/home.component';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorPlatformComponent } from './errors/error-platform/error-platform.component';
import { Error404Component } from './errors/error404/error404.component';
import { Error403Component } from './errors/error403/error403.component';
import { ThanksComponent } from './views/thanks/thanks.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { GlobalConstants } from './const/global-constants';
import { SharedModule } from './shared/shared.module';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { AngularDeviceInformationService } from 'angular-device-information';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorPlatformComponent,
    Error404Component,
    Error403Component,
    ThanksComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    AngularDeviceInformationService,

    GlobalConstants,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
