import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  urlId: string | undefined = '';
  isButtonBlocked: boolean = false;
  showUrlError: boolean = false;

  private TUTORIAL_URL = '/tutorial';

  constructor(
    private _loadingCtrl: LoadingController,
    private _router: Router,
    private _actRoute: ActivatedRoute,
    private _homeService: HomeService
  ) {}

  ngOnInit(): void {
    this._homeService.uuid$.subscribe((currentUUID) => {
      if (
        !currentUUID ||
        currentUUID == '' ||
        !this._checkIsAValidUUID(currentUUID)
      ) {
        this._actRoute.params.subscribe((params) => {
          this.showUrlError = false;
          this.urlId = params['id'];
          if (!this.urlId || !this._checkIsAValidUUID(this.urlId)) {
            this.showUrlError = true;
            this.isButtonBlocked = true;
            return;
          } else {
            this._homeService.uuid$.next(this.urlId);
            this._showLoading();
          }
        });
      } else {
        this.urlId = currentUUID;
        this._showLoading();
      }
    });
  }

  navigateToTutorial(): void {
    if (this.isButtonBlocked) return;
    this._router.navigate([this.TUTORIAL_URL]);
  }

  private async _showLoading() {
    const loading: HTMLIonLoadingElement = await this._loadingCtrl.create({
      message: 'Securizando comunicación',
    });
    loading.present();
    this._securalizeData(loading);
  }

  private _securalizeData(loading: HTMLIonLoadingElement): void {
    if (!this.urlId) return;
    this._homeService.validateUrlId(this.urlId).subscribe({
      next: (response) => {
        if (response) {
          localStorage.setItem('uuid', this.urlId!);
          loading.dismiss();
        } else {
          this.showUrlError = true;
          this.isButtonBlocked = true;
          loading.dismiss();
        }
      },
      error: (error) => {
        console.log(error);
        this.showUrlError = true;
        this.isButtonBlocked = true;
        loading.dismiss();
      },
    });
  }

  private _checkIsAValidUUID(value: string): boolean {
    const uuidRegex = new RegExp(
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    );
    return uuidRegex.test(value);
  }
}
