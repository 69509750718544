<ion-content class="no-scroll">
  <div class="h-full flex flex-col justify-evenly items-center">
    <ng-lottie class="lottie-animation" [options]="animation"> </ng-lottie>

    <ion-text
      color="dark"
      class="flex flex-col justify-center items-center lottie-text ion-padding"
    >
      <h3 class="w-8/12 font-bold">
        Tu proceso de registro se completó con éxito. Ya podes cerrar la
        pestaña.
      </h3>
      <h1 class="font-semibold">¡Muchas gracias!</h1>
    </ion-text>

    <section class="flex flex-row justify-between items-center gap-1">
      <ion-text class="mr-1">
        <span>Powered by</span>
      </ion-text>
      <ion-img class="footer-logo w-9 h-9" src="../../../assets/obd-logo.svg">
      </ion-img>
      <ion-text class="font-bold leading-5 text-base">
        <span>Onboarding Digital</span>
      </ion-text>
    </section>
  </div>
</ion-content>
