import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Steps } from '../const/steps';
import { IAPIValidateResponse } from '../interfaces/apiValidateImageResponse';
import { IAPIValidateImages } from '../interfaces/apiValidateImages';
import { IStoreImages } from '../interfaces/storeImages';
import { UrlFactory } from '../utils/url-factory';

@Injectable({
  providedIn: 'root',
})
export class ScanningService {
  currentStep$: BehaviorSubject<number> = new BehaviorSubject<number>(
    Steps.SCAN_DNI_FRONT
  );
  storedImages$: BehaviorSubject<IStoreImages> =
    new BehaviorSubject<IStoreImages>({
      frontDni: '',
      backDni: '',
      faceNeutral: '',
      faceGesture: '',
    });

  tutorialPassed: boolean = false;

  currentGestureId: number = 0;
  currentDocumentType: number = 0;

  constructor(private _router: Router, private _http: HttpClient) {}

  validateImages(images: IAPIValidateImages): Observable<IAPIValidateResponse> {
    const url = UrlFactory.getValidateUrl();
    return this._http.post<IAPIValidateResponse>(url, images);
  }

  checkStoredImages(step: number): void {
    const storedImages = this.storedImages$.value;
    switch (step) {
      case Steps.SCAN_DNI_FRONT:
        if (storedImages.frontDni === '') {
          this._router.navigate(['scanning/first-step']);
        }
        break;
      case Steps.SCAN_DNI_BACK:
        if (storedImages.backDni === '') {
          this._router.navigate(['scanning/first-step']);
        }
        break;
      case Steps.SCAN_FACE_NEUTRAL:
        if (storedImages.faceNeutral === '') {
          this._router.navigate(['scanning/first-step']);
        }
        break;
      case Steps.SCAN_FACE_GESTURE:
        if (storedImages.faceGesture === '') {
          this._router.navigate(['scanning/first-step']);
        }
        break;
    }
  }

  //Esta funcion valida que el usuario este en el paso que deba estar y no saltee ningun otro.
  //Deberia evitar que navegue libremente por la aplicacion si no hizo los escaneos anteriores.
  validateStep(): boolean {
    const storedImages = this.storedImages$.value;
    const currentStep = this.currentStep$.value;
    if (currentStep > 3 && currentStep < 7) {
      if (storedImages.frontDni === '') {
        this._router.navigate([`scanning/first-step`]);
        return false;
      }
    }
    if (currentStep >= 7 && currentStep < 10) {
      if (storedImages.frontDni === '') {
        this._router.navigate([`scanning/fourth-step`]);
        return false;
      }
    }
    if (currentStep >= 10 && currentStep <= 12) {
      if (storedImages.frontDni === '') {
        this._router.navigate([`scanning/seventh-step`]);
        return false;
      }
    }
    return true;
  }
}
