import { EventEmitter, Injectable } from '@angular/core';
import * as faceapi from 'face-api.js';
import { BehaviorSubject } from 'rxjs';
import { Expressions } from '../const/expressions';

@Injectable({
  providedIn: 'root',
})
export class FaceApiService {
  public globalFaceApi: any;

  modelsLoaded$: EventEmitter<boolean> = new EventEmitter<boolean>();
  metadata$: BehaviorSubject<
    { landmark: any; expressions: Expressions } | false
  > = new BehaviorSubject<{ landmark: any; expressions: any } | false>(false);

  private models = [
    faceapi.nets.ssdMobilenetv1.loadFromUri('./../../assets/models'),
    faceapi.nets.faceLandmark68Net.loadFromUri('./../../assets/models'),
    faceapi.nets.faceRecognitionNet.loadFromUri('./../../assets/models'),
    faceapi.nets.faceExpressionNet.loadFromUri('./../../assets/models'),
  ];

  constructor() {
    this.globalFaceApi = faceapi;
    this.loadModels();
  }

  async loadModels() {
    await Promise.all(this.models).then(() => {
      this.modelsLoaded$.emit(true);
    });
  }

  getMetadataFromFace = async (videoElement: any) => {
    const { globalFaceApi } = this;

    const detections = await globalFaceApi
      .detectAllFaces(videoElement.nativeElement)
      .withFaceLandmarks()
      .withFaceExpressions();

    const faces = detections.length;
    if (detections[0]) {
      const landmark = detections[0].landmarks || null;
      const expressions = detections[0].expressions || null;
      this.metadata$.next({ landmark, expressions });
    }
  };
}
