<ion-header class="ion-no-border">
  <ion-toolbar class="w-full mt-2">
    <div class="flex flex-col items-center w-full">
      <ion-img class="w-8 h-8" src="../../../assets/obd-logo.svg"> </ion-img>
      <ion-text class="my-1">
        <p class="font-bold">Onboarding Digital</p>
      </ion-text>
    </div>
  </ion-toolbar>
</ion-header>
